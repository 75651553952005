import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles,withStyles } from '@material-ui/core/styles';

import { Redirect } from 'react-router';

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://www.saidaonline.com/new/uploads/news/700x439/19/02/Doc-P-560277-636867706629984168.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'left',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

  class SignInSide extends React.Component {
  

  constructor(){
    super();
   
    this.handlerchange=this.handlerchange.bind(this);
    this.submitdata=this.submitdata.bind(this);
    this.enterclick=this.enterclick.bind(this);
    this.getuserprofile=this.getuserprofile.bind(this);

    

    // if(localStorage.getItem('Loggedin'))
    // window.location = '#/admin/Warehouse';

if(localStorage.getItem('rememberme'))
this.state=({
  Username:localStorage.getItem('Username'),
  Password:localStorage.getItem('Password'),
  Error:"",
  rememberme:true,
  // redirect:true
})
else
this.state=({
  Username:"",
  Password:"",
  Error:"",
  rememberme:false,
  // redirect:false
})



  }

  handlerchange(event){
    const {id,value,checked,type}=event.target

    if(type=="checkbox"){
      this.setState(
        {
          [id]:checked
        }
      );
    }
    else{

      this.setState(
        {
          [id]:value
        }
      );

    }
    
    

  }

  enterclick = (e) => {
    if (e.key === 'Enter') {
      this.submitdata();
    }
  }

  submitdata(){
    
     
     
    const requestOptions = {
      method: 'POST',
      
      body: JSON.stringify(this.state)
  };
  
  fetch(`${process.env.REACT_APP_API_BASE_URL}`+"login/login.php", requestOptions)
      .then(response => response.json())
      .then(data => {
      //  this.setState({redirect: true});

       
        if(data!=null){
       
        

        localStorage.setItem('Fullname', data[0].fullname);
        localStorage.setItem('Username', data[0].username);
        localStorage.setItem('Password', data[0].password);
        localStorage.setItem('Loggedin', true);
        localStorage.setItem('isAdmin', data[0].isadmin);
        // localStorage.setItem('can_add_tickets', data[0].can_add_tickets);
        // localStorage.setItem('assign_tickets_by_sla', data[0].assign_tickets_by_sla);
        
        localStorage.setItem('user_id', data[0].serial);

        
        localStorage.setItem('rememberme', this.state.rememberme);

        this.getuserprofile(data[0].role)
        }else{
          this.setState(
            {
              'Error':"Wrong Username Or Password!"
            }
          );
        }
      })
      .catch(error => {
        alert("error");
        console.error('There was an error!', error);

    });
    
  }


  getuserprofile(profileid){
    
   
  
  fetch(`${process.env.REACT_APP_API_BASE_URL}`+"login/access.php?profileid="+profileid)
      .then(response => response.json())
      .then(data => {
       
       
        if(data!=null){
       
        //console.log(data);
          localStorage.setItem('roles', JSON.stringify(data));
      
 

         window.location = './';
        }else{
          this.setState(
            {
              'Error':"Wrong Username Or Password!"
            }
          );
        }
       // this.showNotification("Success!","success")
      })
      .catch(error => {
        alert("error");
        // this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
       // this.showNotification("Error!!","danger")

    });
    
  }
  
  render(){
    const { classes } = this.props;
    // if(this.state.redirect){
    //   return(<Redirect to={'/home'} />)
    // }
  return (

    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onChange={this.handlerchange}
              id="Username"
              label="Username"
              name="email"
              value={this.state.Username}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              onKeyDown={this.enterclick}
              onChange={this.handlerchange}
              label="Password"
              value={this.state.Password}
              type="password"
              id="Password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={
              <Checkbox  
                onChange={this.handlerchange} 
                checked={this.state.rememberme} 
                id="rememberme"
                color="primary" />
              }
              label="Remember me"
            />
            <span style={{color:'red'}}>
            {this.state.Error}
            </span>
            <Button
              type="button"
              fullWidth
              onClick={this.submitdata}
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */}
              </Grid>
              <Grid item>
                {/* <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link> */}
              </Grid>
            </Grid>
            <Box mt={5}>
              {/* <Copyright /> */}
              
             
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
}
export default withStyles(styles)(SignInSide) 
