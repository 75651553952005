/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles,useTheme  } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import PerfectScrollbar from "perfect-scrollbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Notifications from "@material-ui/icons/Notifications";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import clsx from 'clsx';

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

import styles1 from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Button from "components/CustomButtons/Button.js";
import Collapse from '@material-ui/core/Collapse';


const useStyles = makeStyles(styles);
const useStyles1 = makeStyles(styles1);
let ps;

const drawerWidth = 260;

const useStyles_drawer = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));




export default function Sidebar(props) {
  const classes = useStyles();
  const classes1 = useStyles1();

  const classes_drawer = useStyles_drawer();

  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]: activeRoute("admin")
  });

  //perfect scroll
  const mainPanel = React.createRef();

  const {setopen_drawer,open_drawer}=props;

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const resizeFunction = () => {
    // if (window.innerWidth >= 960) {
    //   setMobileOpen(false);
    // }
  };


  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const { color, logo, image, logoText, routes } = props;

  const [open, setOpen] = React.useState([]);
  const [openNotification, setOpenNotification] = React.useState(null);

  const roles=JSON.parse(localStorage.getItem('roles'));
  var curr=[];

  const logout_function=()=>{

    
    
    

    var rememberme= localStorage.getItem('rememberme');
    var Username= localStorage.getItem('Username');
    var Password= localStorage.getItem('Password');

       localStorage.clear();  

       
       if(rememberme==true){
        localStorage.setItem('Username', Username);
        localStorage.setItem('Password', Password);
        localStorage.setItem('rememberme', true);
         
       }
       localStorage.setItem('roles', "[]");
       window.location = './';

  }

  const handleClick = (e,name) => {

    console.log(e);
    console.log(name);
    setOpen({[name]: !open[name]});
  };
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var activePro = " ";
        var listItemClasses;
        if (prop.path === "/Logout") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop.layout + prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
        });

        if(prop.parent){

          var canview=false;
          prop.tabs.forEach(element => {
              
            
            
           if(roles.find(e => e.formname==element.name)){
             canview=true;
             
           }
            
          });
        return (
          // <NavLink
          //   to={prop.layout + prop.path}
          //   className={activePro + classes.item}
          //   activeClassName="active"
          //   key={key}
          // >
          canview?
          <span key={key} onClick={(e)=>handleClick(e,prop.name)}>
            <ListItem button className={open_drawer?classes.itemLink + listItemClasses:classes.itemLinkClosing + listItemClasses}>
              {typeof prop.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop.icon}
                </Icon>
              ) : 
              (
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}

              <ListItemText
                primary={props.rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })}
                disableTypography={true}
              />
               {open[prop.name] ? <ExpandLess   className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })} /> : <ExpandMore  className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })} />}
              
            </ListItem>
            
            <Collapse in={open[prop.name]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>              
           
                {prop.tabs.map((prop1, key1) => {
 
 curr= roles.filter((role) => role.formname==prop1.name);
 if(curr.length!=0)
 if(curr[0].canview==1){
   return(
   
    <NavLink 
             to={prop1.layout + prop1.path}
             className={activePro + classes.item}
             activeClassName="active"
             key={key1}
          >
          <ListItem button className={classes.nested}>
     {typeof prop1.icon === "string" ? (
                <Icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                >
                  {prop1.icon}
                </Icon>
              ) : 
              (
                <prop1.icon
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                />
              )}
     <ListItemText primary={props.rtlActive ? prop1.rtlName : prop1.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: props.rtlActive
                })} />
   </ListItem>
   </NavLink>
   )
              }
                })
              
              }
              </List>
          </Collapse>
            {/* </NavLink> */}
          </span>
     :""
     );
            }
        else{

          if(prop.path === "/Logout"){
            return (
              <span
              key={key}
              onClick={logout_function}
               
                className={activePro + classes.item}
              
              >
                <ListItem button className={open_drawer?classes.itemLink + listItemClasses:classes.itemLinkClosing + listItemClasses}>
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    />
                  )}
                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: props.rtlActive
                    })}
                    disableTypography={true}
                  />
                </ListItem>
              </span>
            );
          }

          else{

          
          
 curr= roles.filter((role) => role.formname==prop.name);
 if(curr.length!=0)
 if(curr[0].canview==1){

  return (
    <NavLink
      to={prop.layout + prop.path}
      className={activePro + classes.item}
      activeClassName="active"
      key={key}
    >
      <ListItem button className={open_drawer?classes.itemLink + listItemClasses:classes.itemLinkClosing + listItemClasses}>
        {typeof prop.icon === "string" ? (
          <Icon
            className={classNames(classes.itemIcon, whiteFontClasses, {
              [classes.itemIconRTL]: props.rtlActive
            })}
          >
            {prop.icon}
          </Icon>
        ) : (
          <prop.icon
            className={classNames(classes.itemIcon, whiteFontClasses, {
              [classes.itemIconRTL]: props.rtlActive
            })}
          />
        )}
        <ListItemText
          primary={props.rtlActive ? prop.rtlName : prop.name}
          className={classNames(classes.itemText, whiteFontClasses, {
            [classes.itemTextRTL]: props.rtlActive
          })}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
  );
}
 }
        }
    
        
      })}


      
    </List>
  );
  var brand = (
    !open_drawer?
    <div>
       <ListItem button className={classes.itemLinkClosing }>
               
                    {/* <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses, {
                        [classes.itemIconRTL]: props.rtlActive
                      })}
                    /> */}
                  
                  <ArrowForwardIcon
                  onClick={(e)=>{setopen_drawer(!open_drawer);}}
                  style={{zIndex:"150"}}
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                  />
                </ListItem>
    </div>
    :
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"50px"}} className={classes.logo}>
      <a 
        href="http://www.civildefense.gov.lb/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        CIVIL DEFENSE 
        
         {/* <div className={classes.logoImage}>
         <Button
         className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
         
          onClick={handleClickNotification}
          // className={classes.buttonLink}
        >
          <Notifications className={classes1.icons} />
          <span className={classes1.notifications}>0</span>
          <Hidden mdUp implementation="css">
            <p onClick={handleCloseNotification} className={classes.linkText}>
              Notification
            </p>
          </Hidden>
        </Button>

         
        </div> */}
      </a>
 <div >
   <ArrowBackIcon
                  onClick={(e)=>{setopen_drawer(!open_drawer);}}
                  style={{zIndex:"150",paddingLeft:"15px"}}
                  className={classNames(classes.itemIcon, whiteFontClasses, {
                    [classes.itemIconRTL]: props.rtlActive
                  })}
                  />

 </div>
       
         
    </div>
  );
  return (
    <div>
     
      {/* <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}  >
          
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden> */}
      <Hidden smDown implementation="css">
        <Drawer
          // anchor={props.rtlActive ? "right" : "left"}
          // variant="permanent"
          // open
          // classes={{
          //   paper: classNames(classes.drawerPaper, {
          //     [classes.drawerPaperRTL]: props.rtlActive
          //   })
          // }}
          variant="permanent"
          className={clsx(classes_drawer.drawer, {
            [classes_drawer.drawerOpen]: open_drawer,
            [classes.drawerClose]: !open_drawer,
          })}
          classes={{
            paper: clsx({
              [classes_drawer.drawerOpen]: open_drawer,
              [classes_drawer.drawerClose]: !open_drawer,
            }),
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper} ref={mainPanel} >{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
