import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Check from "@material-ui/icons/Check";
import CustomToolbar from "../../CustomToolbar";
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';


import IconButton from "@material-ui/core/IconButton";
import { Create, Delete, Add } from "@material-ui/icons";

import LinearProgress from '@material-ui/core/LinearProgress';

import { deepOrange, deepPurple } from '@material-ui/core/colors';

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from '@date-io/date-fns';

import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import styles_theme from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useStyles_theme = makeStyles(styles_theme);

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles1 = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    marginTop: "26px",
    width: "100%",
  },
  root_avatar: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
},
}));

const useStyles = makeStyles(styles);

export default function Users(props) {

  
  const roless=JSON.parse(localStorage.getItem('roles'));
  var curr_role= roless.filter((role) => role.formname==props.title);
// const [color, setColor] = React.useState("red");
  const classes = useStyles();
  const classes_theme = useStyles_theme(); //theme css
  const classes_form = useStyles1(); //theme css

  const [Submitform, setSubmitform] = useState({
    fullname:"",
    username:"",
    password:"",
    isadmin:false,
    address:"",
    mobile:"",
    email:"",
    department:"",
    active:true,

  }); //form submit

  const [items, setItems] = useState([]); //table items
  const [RowID, setRowID] = useState(-1); //table items
   
  const [loading, setloading] = useState(true); //loader
 
  const [branch, setBranch] = useState([]);
  const [modal_Title, setmodal_Title] = useState("Add"); //table items
  const [user, setUser] = useState([]);
  const [driver, setDriver] = useState([]);
  const [station, setStation] = useState([]);
  const [card, setCard] = useState([]);
  const [vehicle, setVehicle] = useState([]);

  const MySwal = withReactContent(Swal); //swal

  const classes1 = useStyles1();
  const [open, setOpen] = useState(false);

  const handleClickOpen = (rowID, modal_Title) => {
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);

    /////////////////////////////////////////////

      fetch(`${process.env.REACT_APP_API_BASE_URL}`+
      "Users/index.php?id=" + items[rowID].serial
    )
      .then((response) => response.json())
      .then((data) => {
        setSubmitform({

          fullname:data[0]["fullname"],
          username:data[0]["username"],
          password:data[0]["password"],
          isadmin: data[0]["isadmin"] == 1 ? true : false,
          address: data[0]["address"],
          mobile: data[0]["mobile"],
          email: data[0]["email"],
          department: data[0]["department"],
        active: data[0]["active"] == 1 ? true : false,
    
});
      })
      .catch((error) => {
        alert("error");
        console.log("There was an error!", error);
      });
    ////////////////////////////////////////////////
  };

  const handlechange_date = (date) => {
    setSubmitform({
      ...Submitform,
      "date": new Date(date)
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlesave = () => {

  
    if (RowID == -1) {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(Submitform),
      };

        fetch(`${process.env.REACT_APP_API_BASE_URL}`+
        "Users/store.php?action=1",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data == 0) {
            //error
          } else {
            var old_arr = items;

            old_arr.push(data[0]);

            setItems(old_arr);
            
            handleClose();
          }
        })
        .catch((error) => {
          alert("error");
          console.log("There was an error!", error);
        });
    } else {

      

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ID:items[RowID].serial,...Submitform}),
      };

        fetch(`${process.env.REACT_APP_API_BASE_URL}`+
        "Users/store.php?action=3",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data == 0) {
            //error
          } else {

            let arr_items = items;
 
            
     // 2. Make a shallow copy of the item you want to mutate
    arr_items[RowID] =data[0]
            // var old_arr = items;

            console.log(arr_items);
 
            // old_arr.push(data[0]);

             setItems(arr_items);
             handleClose();
          }
        })
        .catch((error) => {
          alert("error");
          console.log("There was an error!", error);
        });

    }
  };

  const add_click = () => {
    setOpen(true);
    setRowID(-1);
    setSubmitform({ 
      fullname:"",
      username:"",
      password:"",
      isadmin:false,
      address:"",
      mobile:"",
      email:"",
      department:"",
      active:true,

    });
    setmodal_Title("Add");
  };

  const handlechange_select = (event) => {
    const { name, value } = event.target;

    setSubmitform({
      ...Submitform,
      [name]: value,
    });
  };


   

  const handlechangeinput = (event) => {
    const { id, value, checked, type } = event.target;

    console.log(event.target);

    console.log("value", value);
    console.log("type", type);

    if (type == "checkbox") {
      setSubmitform({
        ...Submitform,
        [id]: checked,
      });
    } else {
      setSubmitform({
        ...Submitform,
        [id]: value,
      });
    }

    console.log(Submitform);
  };

  function deleterows(row) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(row),
    };

    MySwal.fire({
      title: "<span style='color:white'>Are you sure? You won't be able to revert this!</span>",
      icon: "warning",
      toast:true,
      position:"top-end",
      background:"#212529",
      showCancelButton: true,
      confirmButtonColor: "#04d401",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
          fetch(`${process.env.REACT_APP_API_BASE_URL}`+
          "Users/store.php?action=2",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            var data1 = items;

            row.map((row) => {
              data1 = data1.filter((user) => user.serial !== row.serial);
            });

            console.log(data1);

            setItems(data1);
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });

        //Swal.fire("Deleted!", "Your file has been deleted.", "success");
        Swal.fire({
          title: "<span style='color:white'>Deleted!</span>",
          icon: "success",
          toast:true,
          position:"top-end",
          background:"#212529",
          showCancelButton: false,
          timer:3000,
          showConfirmButton:false
        })
      }
    });
  }

  //get data for table
  useEffect(() => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}`+"Users/index.php")
      .then((response) => response.json())
      .then((data) => {
        //  alert("use");
if(data!=null)
        {
        setItems(data);
        setloading(false)
        }
        // this.setState({
        //     loading:false,
        //     user:data
        // })
      })
      .catch((error) => {
        alert(error.toString());

        //  this.showNotification("Error!!","danger")
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Department/index.php?action=1")
      .then((response) => response.json())
      .then((data) => {
        const ruleList = data.data.map((data) => (
          <MenuItem value={data.serial}>{data.name}</MenuItem>
        ));
        setBranch(ruleList);
      })
      .catch((error) => {
        alert(error.toString());

        //  this.showNotification("Error!!","danger")
      });
  }, []);
  const columns = [

    {
        name: "fullname",
        label: "Fullname"
    },
    {
        name: "username",
        label: " User Name"
      },
      {
        name: "isadmin",
        label: "Admin",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <div>{value == 1 ? "true" : "false"}</div>
          )
        }
       },
      {
        name: "address",
        label: "Address"
      },
      {
        name: "mobile",
        label: "Mobile"
      },
      {
        name: "email",
        label: "Email"
      },
       {
        name: "department_name",
        label: "Department"
      },

      {
        name: "active",
        label: "Active",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <div>{value == 1 ? "true" : "false"}</div>
          ),
        }
       },
        {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        display: curr_role[0]['canedit'] == "1" || curr_role[0]['candelete'] == "1" ? true :false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              {
   curr_role[0]['canedit'] == "1"?
<Tooltip title={"Edit"}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {

                    handleClickOpen(dataIndex, "Edit");
                  }}
                >
                  <Create></Create>
                </IconButton>
              </Tooltip>


   :""


              }

{
curr_role[0]['candelete'] == "1"?
<Tooltip title={"Delete"}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    // let val = this.state.user[dataIndex].serial;
                    //var del_array = {row:items[dataIndex].serial};
                    //deleterow(del_array);

                    let myItems = [];

                    myItems.push({ serial: items[dataIndex].serial });

                    console.log(myItems);

                    deleterows(myItems);
                  }}
                >
                  <Delete></Delete>
                </IconButton>
              </Tooltip>
:""


}              
              
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows:   curr_role[0]['candelete'] == "1"?true  :false,

    onRowsDelete: (rowsDeleted) => {
      let myItems = [];

      rowsDeleted.data.map((anObjectMapped, index) => {
        myItems.push({ serial: items[anObjectMapped.dataIndex].serial });
      });
      //console.log(this.props.items[anObjectMapped.dataIndex].serial);

      //  console.log(myItems);

      deleterows(myItems);

      return false;
    },

   
    customToolbar: () => {

      return (<span>
        {
         curr_role[0]['canedit'] == "1"? 
        <CustomToolbar listener={add_click} />  
        :""
        }
         </span>);
      
    },
    //this.deleteRows
  };

  return (
    <GridContainer>
      
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>{props.title}</h4>
            {/* <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p> */}
          </CardHeader>
          <CardBody>
          {loading ?     <div style={{textAlign:"center"}}><CircularProgress /></div> :    <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title=""
                data={items}
                //{this.state.user}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
         }
         </CardBody>
        </Card>
      </GridItem>

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                {modal_Title + " " + props.title}
              </Typography>
              <Button autoFocus color="inherit" onClick={handlesave}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="Full Name"
                  id="fullname"
                  inputProps={{
                    onChange: handlechangeinput,
                    value: Submitform.fullname,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="User name"
                  id="username"
                  inputProps={{
                    onChange: handlechangeinput,
                    value: Submitform.username,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="Password"
                  id="password"
                  inputProps={{
                  type:"password",
                    onChange: handlechangeinput,
                    value: Submitform.password,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="Address"
                  id="address"
                  inputProps={{
                    onChange: handlechangeinput,
                    value: Submitform.address,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="Mobile"
                  id="mobile"
                  inputProps={{
                    onChange: handlechangeinput,
                    value: Submitform.mobile,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="Email"
                  id="email"
                  inputProps={{
                    onChange: handlechangeinput,
                    value: Submitform.email,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <InputLabel id="department-label">Department</InputLabel>
                  <Select
                    labelId="department-label"
                    name="department"
                    value={Submitform.department}
                    onChange={handlechange_select}
                  >
                    {
                      branch
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div style={{ paddingTop: "2rem" }}>
                  Admin
                  <Checkbox
                    checked={Submitform.isadmin}
                    id="isadmin"
                    tabIndex={-1}
                    onClick={handlechangeinput}
                    checkedIcon={<Check className={classes_theme.checkedIcon} />}
                    icon={<Check className={classes_theme.uncheckedIcon} />}
                    classes={{
                      checked: classes_theme.checked,
                      root: classes_theme.root,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={3}>
                <div style={{ paddingTop: "2rem" }}>
                  Active
                  <Checkbox
                    checked={Submitform.active}
                    id="active"
                    tabIndex={-1}
                    onClick={handlechangeinput}
                    checkedIcon={<Check className={classes_theme.checkedIcon} />}
                    icon={<Check className={classes_theme.uncheckedIcon} />}
                    classes={{
                      checked: classes_theme.checked,
                      root: classes_theme.root,
                    }}
                  />
                </div>
              </Grid>
              
            </Grid>
          </div>
        
          
        </Dialog>
      </div>
    </GridContainer>
  );

  function getMuiTheme() {
    return createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            //  height: 'inherit',
            boxShadow: "none",
          },
          responsiveBase: {
            height: 'calc(100vh - 300px)!important'
          },
          responsiveScroll: {
            //  maxHeight: 'none',
            //  height: 'calc(100% - 128px)'
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "#ebebeb",
             
            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
           paddingTop:"0px",
           paddingBottom:"0px"
          },
        },
      },
    });
  }
}
