import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
// import Privilege from "views/Userrole/Privilege.js";
// import FollowUp from "views/FollowUp/FollowUp.js";
// import FollowUp_archived from "views/FollowUp_archived/FollowUp_archived.js";
import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import CardsDetail from "views/cards_detail/cards_detail";
import bgImage from "assets/img/fire_bg.jpg";
import logo from "assets/img/C-D-logo.png";
import SignInSide from "components/SignInSide/SignInSide";

let ps;
const adminRoutes = routes.filter((route) => route.layout === "/admin");
var roles=JSON.parse(localStorage.getItem('roles'));

if(roles==null)
roles=[];
var curr=[];

const switchRoutes = (
  <Switch>
    {adminRoutes
      .map((route, key) => {
        if (!!route.parent && route.tabs) {
          return route.tabs.map((child, key) => {
            
             curr= roles.filter((role) => role.formname==child.name);

         

              if(curr.length!=0)
            if(curr[0].canview==1){
return(<Route
  path={child.layout + child.path}
  // component={child.component}
  component={() => <child.component title={child.name} />}
  key={key}
/>)
            }


          }  
          );
        }

        curr= roles.filter((role) => role.formname==route.name);
        if(curr.length!=0)
         if(curr[0].canview==1){
        return (
          <Route
            path={route.layout + route.path}
            // component{route.component}
            component={() => <route.component title={route.name} />}
            key={key}
          />
        );
      }
      })
      .flat()}
    {/* [1,2,3,[5,10],[3]].flat => [1,2,3,5,10,3] */}
    <Route
      path="/admin/CardsDetail/:card_serial"
      component={() => <CardsDetail title="CardsDetail" />}
      key={routes.length + 1}
    />

    <Route
      path="/Login"
      component={() => <SignInSide title="CardsDetail" />}
      key={routes.length + 2}
    />




    <Redirect from="/admin" to="/admin/Home" />
  </Switch>
);

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("red");
  const [open_drawer, setopen_drawer] = React.useState(true);

  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Sidebar
      open_drawer={open_drawer}
      setopen_drawer={setopen_drawer}
        routes={routes}
        logoText={"Civil Defense"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={!open_drawer?classes.mainPanelClosing:classes.mainPanel} ref={mainPanel}>
        {/* <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        /> */}
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {/* {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : ( */}
        <div 
        // className={classes.map}
        >
          {switchRoutes}
        
        </div>
        {/* )} */}
        {/* {getRoute() ? <Footer /> : null} */}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
        /> */}
      </div>
    </div>
  );
}
