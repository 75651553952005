import React, { useEffect, useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Check from "@material-ui/icons/Check";
import CustomToolbar from "../../CustomToolbar";
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Scanner from "components/Scanner/Scanner.js";
import Scanner from "components/Scanner2/Scanner.js";
import Result from "components/Scanner2/Result.js";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

// import "components/Scanner/style.css";

import "./home.css"


import IconButton from "@material-ui/core/IconButton";
import { Create, Delete, Add, CameraAlt, ExitToApp } from "@material-ui/icons";
// import {DocumentScanner} from '@mui/icons-material';

import LinearProgress from '@material-ui/core/LinearProgress';

import { deepOrange, deepPurple } from '@material-ui/core/colors';

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from '@date-io/date-fns';
import * as moment from 'moment'


import { useHistory, Link } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import styles_theme from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import CustomToolbar_filter from "../../CustomToolbar_filter";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getSyntheticTrailingComments } from "typescript";
import { render } from "react-dom";

const useStyles_theme = makeStyles(styles_theme);

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles1 = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    marginTop: "26px",
    width: "100%",
    color: 'red'
  },
  scanner: {
    marginTop: "40px",
    marginLeft: "30px",
  },
  root_avatar: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
}));

const useStyles = makeStyles(styles);

export default function Home(props) {
  const history = useHistory();

  const roless = JSON.parse(localStorage.getItem('roles'));
  var curr_role = roless.filter((role) => role.formname == props.title);
  const user_id = JSON.parse(localStorage.getItem('user_id'));

  // const [color, setColor] = React.useState("red");
  const classes = useStyles();
  const classes_theme = useStyles_theme(); //theme css
  const classes_form = useStyles1(); //theme css
  let dat = new Date(Date.now());
  const [branchID, setBranchID] = useState([]);
  let date = new Date(Date.now()); date.setDate(date.getDate() - 7);

  const [Submitform, setSubmitform] = useState({
    user_id: user_id,
    driver_name: "",
    station_id: "",
    card_number: "",
    vehicle_id: "",
    odometer: "",
    date: dat,
    branch_id: "",
    type: "vehicle"

  }); //form submit
  const [Submitfilter, setSubmitfilter] = useState({
    driver_name: "",
    station_id: "",
    card_number: "",
    branch_id: "",
    vehicle_id: "",
    from_date: date,
    to_date: new Date(Date.now()),


  });

  const [items, setItems] = useState([]); //table items
  const [RowID, setRowID] = useState(-1); //table items

  const [loading, setloading] = useState(true); //loader
  const [camera, setCamera] = useState(false);


  const [modal_Title, setmodal_Title] = useState("Add"); //table items
  const [user, setUser] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [driver, setDriver] = useState([]);
  const [station, setStation] = useState([]);
  const [card, setCard] = useState([]);
  const [vehicle, setVehicle] = useState([]);

  const MySwal = withReactContent(Swal); //swal

  const classes1 = useStyles1();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const handleClickOpen = (rowID, modal_Title) => {
    getStation();
    getBranches();
    // getVehicle();
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);

    /////////////////////////////////////////////

    fetch(`${process.env.REACT_APP_API_BASE_URL}` +
      "Transactions/index.php?id=" + items[rowID].serial
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data[0]["station_id"]);
        setSubmitform({

          user_id: data.data[0]["user_id"],
          // driver_id:data[0]["driver_id"],
          driver_name: data.data[0]["driver_name"],
          card_number: data.data[0]["card_number"],
          station_id: data.data[0]["station_id"],
          vehicle_id: data.data[0]["vehicle_id"],
          odometer: data.data[0]["odometer"],
          date: data.data[0]["date"],
          branch_id: data.data[0]["branch_id"],
          type: data.data[0]["type"]

        });
      })
      .catch((error) => {
        alert("error");
        console.log("There was an error!", error);
      });
    ////////////////////////////////////////////////
  };

  const handlechange_date = (date) => {
    setSubmitform({
      ...Submitform,
      "date": new Date(date)
    });
  };
  const handleClose = () => {
    setOpen(false);
    setOpenFilter(false);
    setSubmitform({
      ...Submitform,
      card_number: ""
    });
  };
  const handleclear = () => {
    if (Submitfilter.branch_id == branchID) {
      setSubmitfilter({
        driver_name: "",
        station_id: "",
        card_number: "",
        vehicle_id: "",
        from_date: date,
        to_date: new Date(Date.now()),
      })

    } else {
      setSubmitfilter({
        driver_name: "",
        station_id: "",
        card_number: "",
        branch_id: "",
        vehicle_id: "",
        from_date: date,
        to_date: new Date(Date.now()),
      })
    }

  };
  const handlechange_fromdat = (date) => {
    setSubmitfilter({
      ...Submitfilter,
      "from_date": new Date(date)
    });
  };

  const handlechange_todat = (date) => {
    setSubmitfilter({
      ...Submitfilter,
      "to_date": new Date(date)
    });
  };
  const handlesave_filter = () => {

    getTableData(0);
    handleClose();
  };

  const handlechange_radio = (event) => {
    const { name, value } = event.target;
    setSubmitform({
      ...Submitform,
      [name]: value,
      vehicle_id: '',
      driver_name: ''
    });
  };


  const handlesave = () => {
    if (Submitform.branch_id > 0 && Submitform.card_number > 0) {
      if (RowID == -1) {
        const requestOptions = {
          method: "POST",
          body: JSON.stringify(Submitform),
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}` +
          "Transactions/store.php?action=1",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data == "Number Not Valid") {
              alert('Invalid Number !');
            } else if (data == "already used") {
              alert('Card number already used !');
            } else if (data == "Number not valid for this branch") {
              alert('Number not valid for this branch !');
            } else if (data == "Expired Number") {
              alert('Expired Number !');
            }
            else if (data == "Vehicle engine type is not as required") {
              alert(' Vehicle engine type is not as required !');
            } else {
              var old_arr = items;

              old_arr.push(data[0]);

              setItems(old_arr);

              handleClose();
            }
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });
      } else {


        // console.log(items[RowID].serial);
        // return;
        const requestOptions = {
          method: "POST",
          body: JSON.stringify({ ID: items[RowID].serial, ...Submitform }),
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}` +
          "Transactions/store.php?action=3",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data == "Number Not Valid") {
              alert('Invalid Number !');
            } else if (data == "already used") {
              alert('Card number already used !');
            } else if (data == "Number not valid for this branch") {
              alert('Number not valid for this branch !');
            } else if (data == "Expired Number") {
              alert('Expired Number !');
            } else {

              let arr_items = items;


              // 2. Make a shallow copy of the item you want to mutate
              arr_items[RowID] = data[0]
              // var old_arr = items;


              // old_arr.push(data[0]);

              setItems(arr_items);
              handleClose();
            }
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });

      }
    }
    else {
      alert("Please check your card number or branch name ");
    }
  };

  const save_clear = () => {


    if (RowID == -1) {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(Submitform),
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}` +
        "Transactions/store.php?action=1",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data == "Number Not Valid") {
            alert('Invalid Number !');
          } else if (data == "already used") {
            alert('Card number already used !');
          } else if (data == "Number not valid for this branch") {
            alert('Number not valid for this branch !');
          } else if (data == "Expired Number") {
            alert('Expired Number !');
          } else {
            var old_arr = items;

            old_arr.push(data[0]);

            setItems(old_arr);

            setSubmitform({
              user_id: user_id,
              // driver_id:"",
              driver_name: "",
              station_id: "",
              card_number: "",
              vehicle_id: "",
              odometer: "",
              date: dat,
              branch_id: "",
              type: "vehicle"
            });
            alert("Successfully Saved");

          }
        })
        .catch((error) => {
          alert("error");
          console.log("There was an error!", error);
        });
    } else {



      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ ID: items[RowID].serial, ...Submitform }),
      };

      fetch(`${process.env.REACT_APP_API_BASE_URL}` +
        "Transactions/store.php?action=3",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data == "Number Not Valid") {
            alert('Invalid Number !');
          } else if (data == "already used") {
            alert('Card number already used !');
          } else if (data == "Number not valid for this branch") {
            alert('Number not valid for this branch !');
          } else if (data == "Expired Number") {
            alert('Expired Number !');
          } else {

            let arr_items = items;


            // 2. Make a shallow copy of the item you want to mutate
            arr_items[RowID] = data[0]
            // var old_arr = items;


            // old_arr.push(data[0]);

            setItems(arr_items);
            setSubmitform({
              user_id: user_id,
              // driver_id:"",
              driver_name: "",
              station_id: "",
              card_number: "",
              vehicle_id: "",
              odometer: "",
              date: dat,
              branch_id: "",
              type: "vehicle"
            });
            alert("Successfully Updated");

          }
        })
        .catch((error) => {
          alert("error");
          console.log("There was an error!", error);
        });

    }
  };
  const filter_listener = () => {
    setOpenFilter(true);
    getBranches();
    getStation();
    // getVehicle();
  };
  const add_click = () => {
    getBranches();
    // getDrivers();
    getStation();
    // getVehicle();
    setOpen(true);
    setRowID(-1);
    setSubmitform({
      user_id: user_id,
      // driver_id:"",
      driver_name: "",
      station_id: "",
      card_number: "",
      vehicle_id: "",
      odometer: "",
      date: dat,
      branch_id: "",
      type: "vehicle"
    });
    setmodal_Title("Add");
  };

  const handlechange_select = (event) => {
    const { name, value } = event.target;

    setSubmitform({
      ...Submitform,
      [name]: value,
    });
  };
  const handlechange_select_filter = (event) => {
    const { name, value } = event.target;

    setSubmitfilter({
      ...Submitfilter,
      [name]: value,
    });
  };




  const handlechangeinput = (event) => {
    const { id, value, checked, type } = event.target;
    if (type == "checkbox") {
      setSubmitform({
        ...Submitform,
        [id]: checked,
      });
    } else {
      setSubmitform({
        ...Submitform,
        [id]: value,
      });
    }
  };

  const handlechangeinput_filter = (event) => {
    const { id, value, checked, type } = event.target;

    if (type == "checkbox") {
      setSubmitfilter({
        ...Submitform,
        [id]: Submitfilter,
      });
    } else {
      setSubmitfilter({
        ...Submitfilter,
        [id]: value,
      });
    }

  };

  function deleterows(row) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(row),
    };

    MySwal.fire({
      title: "<span style='color:white'>Are you sure? You won't be able to revert this!</span>",
      icon: "warning",
      toast: true,
      position: "top-end",
      background: "#212529",
      showCancelButton: true,
      confirmButtonColor: "#04d401",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}` +
          "Transactions/store.php?action=2",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            var data1 = items;

            row.map((row) => {
              data1 = data1.filter((user) => user.serial !== row.serial);
            });
            setItems(data1);
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });

        //Swal.fire("Deleted!", "Your file has been deleted.", "success");
        Swal.fire({
          title: "<span style='color:white'>Deleted!</span>",
          icon: "success",
          toast: true,
          position: "top-end",
          background: "#212529",
          showCancelButton: false,
          timer: 3000,
          showConfirmButton: false
        })
      }
    });
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Users/index.php?id=" + Submitform.user_id)
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          setUser(data[0]["username"]);
          setBranchID(data[0]["department"]);
          setSubmitfilter({
            ...Submitfilter,
            branch_id: data[0]["department"]
          })
          setBranch(data[0]["department_name"]);
          setloading(false)
        }
      })
      .catch((error) => {
        alert(error.toString());
      });
  }, [Submitform.user_id]);

  const [page, setpage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);


  //get data for table
  const getTableData = (requiredPage) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}Transactions/index.php?fromdat=${moment(Submitfilter.from_date).format("YYYY-MM-DD")}&todat=${moment(Submitfilter.to_date).format("YYYY-MM-DD")}&branch_id=${Submitfilter.branch_id}&driver_name=${Submitfilter.driver_name}&station_id=${Submitfilter.station_id}&card_number=${Submitfilter.card_number}&page=${requiredPage}&vehicle_id=${Submitfilter.vehicle_id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          setItems(data.data);
          setTotalItems(data.total_items);
          setloading(false)
        }

      })
      .catch((error) => {
        alert(error.toString());

      });
  }
  useEffect(() => {
    setpage(0);
    getTableData(0);
  }, [Submitfilter.branch_id]);

  const getBranches = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Department/index.php?branch_id=" + branchID + "&action=1")
      .then((response) => response.json())
      .then((data) => {
        const ruleList = data.data.map((data) => (
          <MenuItem value={data.serial}>{data.name}</MenuItem>
        ));
        setBranchList(ruleList);
      })
      .catch((error) => {
        alert(error.toString());

      });
  }

  const getStation = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Station/index.php?branch_id=" + branchID)
      .then((response) => response.json())
      .then((data) => {
        const ruleList = data.map((data) => (
          <MenuItem value={data.serial}>{data.name}</MenuItem>
        ));
        setStation(ruleList);
      })
      .catch((error) => {
        alert(error.toString());

      });
  }
  const getVehicle = (branch) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Vehicle/index.php?branch_id=" + branch + "&action=1")
      .then((response) => response.json())
      .then((data) => {
        const ruleList = data.data.map((data) => (
          <MenuItem value={data.serial}>{data.plate_number}</MenuItem>
        ));
        setVehicle(ruleList);
      })
      .catch((error) => {
        alert(error.toString());

      });

  }

  useEffect(() => {
    if (Submitform.branch_id) {
      getVehicle(Submitform.branch_id);
    } else if (Submitfilter.branch_id) {
      getVehicle(Submitfilter.branch_id);
    }

  }, [Submitform.branch_id, Submitfilter.branch_id]);

  const list = [
    { value: "No cards available" }
  ];






  const columns = [
    {
      name: "user_name",
      label: " User Name"
    },
    {
      name: "driver_name",
      label: "Driver"
    },
    {
      name: "card_number",
      label: "Card Number"
    },
    {
      name: "card_name",
      label: "Card Name"
    },
    {
      name: "vehicle_name",
      label: "Vehicle"
    },
    {
      name: "vehicle_model",
      label: "Vehicle Model"
    },
    {
      name: "station_name",
      label: "Station Name"
    },
    {
      name: "odometer",
      label: "Odometer"
    },
    {
      name: "date",
      label: "Date"
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        display: curr_role[0]['canedit'] == "1" || curr_role[0]['candelete'] == "1" ? true : false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              {
                curr_role[0]['candelete'] == "1" ?
                  <Tooltip title={"Edit"}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {

                        handleClickOpen(dataIndex, "Edit");
                      }}
                    >
                      <Create></Create>
                    </IconButton>
                  </Tooltip>


                  : ""


              }

              {
                curr_role[0]['candelete'] == "1" ?
                  <Tooltip title={"Delete"}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        let myItems = [];
                        myItems.push({ serial: items[dataIndex].serial });
                        deleterows(myItems);
                      }}
                    >
                      <Delete></Delete>
                    </IconButton>
                  </Tooltip>
                  : ""


              }

            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: curr_role[0]['candelete'] == "1" ? true : false,

    onRowsDelete: (rowsDeleted) => {
      let myItems = [];

      rowsDeleted.data.map((anObjectMapped, index) => {
        myItems.push({ serial: items[anObjectMapped.dataIndex].serial });
      });
      deleterows(myItems);

      return false;
    },


    customToolbar: () => {

      return (<span>
        {
          curr_role[0]['canedit'] == "1" ?
            <CustomToolbar listener={add_click} />

            : ""
        }
        {
          <CustomToolbar_filter filter_listener={filter_listener} />
        }
      </span>);

    },
    filter: false,

    //this.deleteRows
    serverSide: true,
    //count, // Use total number of items
    count: totalItems, // Unknown number of items
    page,
    rowsPerPage: 50,
    rowsPerPageOptions: [50],
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        changePage(tableState.page);
      }
    }
  };
  const changePage = (page) => {
    setpage(page);
    getTableData(page)
  }


  const [scanning, setScanning] = useState(false);
  const [results, setResults] = useState([]);
  const scannerRef = useRef(null);

  const onDetected = result => {
    setSubmitform({
      ...Submitform,
      card_number: result
    });
    setCamera(false);
    setScanning(!scanning);
  };


  const logOut = () => {
    localStorage.clear();
    window.location.reload(false);
  }
  return (
    <GridContainer>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite} style={{ float: 'left' }}>{props.title} <span>-{branch}</span>
            </h4>
            <Link style={{ color: '#000E3E' }} to={{
              pathname: '/Logout',
            }}
            >
              <IconButton
                edge="start"
                color="inherit"
                onClick={logOut}
                class="logout_btn"
              >
                <ExitToApp id="logout_btn2"></ExitToApp>
              </IconButton>
            </Link>
          </CardHeader>
          <CardBody>
            {loading ? <div style={{ textAlign: "center" }}><CircularProgress /></div> : <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title=""
                data={items}
                //{this.state.user}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
            }
          </CardBody>
        </Card>
      </GridItem>

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                {modal_Title + " " + props.title}
              </Typography>
              <Button autoFocus color="inherit" onClick={save_clear}>
                save & clear
              </Button>
              <Button autoFocus color="inherit" onClick={handlesave}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <Grid container spacing={3}>


              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl} >
                  <CustomInput
                    labelText="User Name"
                    id="user_id"
                    inputProps={{
                      disabled: true,
                      onChange: handlechangeinput,
                      value: user,
                    }}
                    formControlProps={{
                      fullWidth: true,
                      style: { margin: "0px" }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl} >
                  <InputLabel id="branch_id-label">Branch Name</InputLabel>
                  <Select
                    labelId="branch_id-label"
                    name="branch_id"
                    value={Submitform.branch_id}
                    onChange={handlechange_select}
                  >
                    {
                      branchList
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={5}>
                <FormControl className={classes_form.formControl} style={{ color: 'red' }}>
                  <CustomInput
                    labelText="Card Number"
                    id="card_number"
                    inputProps={{
                      onChange: handlechangeinput,
                      value: Submitform.card_number,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1}>
                <FormControl className={classes_form.scanner}>
                  <Tooltip title={"Scan"}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      // onClick={() => {
                      //     setCamera(!camera);
                      // }}
                      onClick={() => setScanning(!scanning)}
                    >
                      <CameraAlt></CameraAlt>
                    </IconButton>
                  </Tooltip>
                </FormControl>

              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <InputLabel id="Station-label">Station</InputLabel>
                  <Select
                    labelId="Station-label"
                    name="station_id"
                    value={Submitform.station_id}
                    onChange={handlechange_select}
                  >
                    {
                      station
                    }
                  </Select>
                </FormControl>
              </Grid>
              {Submitform.type == "vehicle" ?
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes_form.formControl}>
                      <CustomInput
                        labelText="Driver Name"
                        id="driver_name"
                        inputProps={{
                          onChange: handlechangeinput,
                          value: Submitform.driver_name,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes_form.formControl}>
                      <InputLabel id="vehicle_id-label">Vehicle</InputLabel>
                      <Select
                        labelId="vehicle_id-label"
                        name="vehicle_id"
                        value={Submitform.vehicle_id}
                        onChange={handlechange_select}
                      >
                        {
                          vehicle
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes_form.formControl}>
                      <CustomInput
                        labelText="Odometer"
                        id="odometer"
                        inputProps={{
                          onChange: handlechangeinput,
                          value: Submitform.odometer,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </> : ""}

              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    disabled
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date"
                    label="Date"
                    value={Submitform.date}
                    style={{ width: "100%" }}
                    onChange={handlechange_date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Type</FormLabel>
                  <RadioGroup row defaultValue="vehicle" aria-label="type" name="type" value={Submitform.type} onChange={handlechange_radio}>
                    <FormControlLabel value="vehicle" control={<Radio />} label="Vehicle" />
                    <FormControlLabel value="other" control={<Radio />} label="Other" />

                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                {/* <div className="scanner"> */}
                <ul className="results">
                  {/* {results.map((result) => (result.codeResult && <Result key={result.codeResult.code} result={result} />))} */}
                </ul>
                <div ref={scannerRef} style={{ position: 'relative', width: '640' }}>
                  {
                    scanning ?
                      <>
                        <canvas className="drawingBuffer" style={{
                          position: 'absolute',
                          top: '0px',
                          // left: '0px',
                          // height: '100%',
                          // width: '100%',
                        }} width="640" height="500" />
                        <Scanner scannerRef={scannerRef} onDetected={onDetected} />
                      </>
                      : ""
                  }

                  {/* {scanning ? <Scanner scannerRef={scannerRef} onDetected={onDetected} /> : null} */}
                </div>
                {/* </div> */}
              </Grid>


              <Grid item xs={12} sm={6}>
                <div className="scanner">
                  {/* {camera && <Scanner onDetected={onDetected} />} */}
                </div>
              </Grid>

            </Grid>
          </div>


        </Dialog>

        <Dialog
          fullScreen
          open={openFilter}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                {"Filter " + props.title}
              </Typography>
              <Button autoFocus color="inherit"
                onClick={handleclear}
              >
                Clear filters
              </Button>

              <Button autoFocus color="inherit"
                onClick={handlesave_filter}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <InputLabel id="branch_id-label">Branch Name</InputLabel>
                  <Select
                    labelId="branch_id-label"
                    name="branch_id"
                    value={Submitfilter.branch_id}
                    onChange={handlechange_select_filter}
                  >
                    {
                      branchList
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <CustomInput
                    labelText="Card Number"
                    id="card_number"
                    inputProps={{
                      onChange: handlechangeinput_filter,
                      value: Submitfilter.card_number,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <InputLabel id="Station-label">Station</InputLabel>
                  <Select
                    labelId="Station-label"
                    name="station_id"
                    value={Submitfilter.station_id}
                    onChange={handlechange_select_filter}
                  >
                    {
                      station
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <CustomInput
                    labelText="Driver Name"
                    id="driver_name"
                    inputProps={{
                      onChange: handlechangeinput_filter,
                      value: Submitfilter.driver_name,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <InputLabel id="vehicle_id-label">Vehicle</InputLabel>
                  <Select
                    labelId="vehicle_id-label"
                    name="vehicle_id"
                    value={Submitfilter.vehicle_id}
                    onChange={handlechange_select_filter}
                  >
                    {
                      vehicle
                    }
                  </Select>
                </FormControl>
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="from_date"
                    label="From Date"
                    value={Submitfilter.from_date}
                    style={{ width: "100%" }}
                    onChange={handlechange_fromdat}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="to_date"
                    label="To Date"
                    value={Submitfilter.to_date}
                    style={{ width: "100%" }}
                    onChange={handlechange_todat}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  /></Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </div>
        </Dialog>


      </div>
    </GridContainer>
  );

  function getMuiTheme() {
    return createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            //  height: 'inherit',
            boxShadow: "none",
          },
          responsiveBase: {
            height: 'calc(100vh - 300px)!important'
          },
          responsiveScroll: {
            //  maxHeight: 'none',
            //  height: 'calc(100% - 128px)'
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "#ebebeb",

            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            paddingTop: "0px",
            paddingBottom: "0px"
          },
        },
      },
    });
  }
}
