import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";



import FilterListIcon from '@material-ui/icons/FilterList';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
  },
};

class CustomToolbar_print extends React.Component {
  
  // handleClick = () => {
  //  alert("clicked on icon!");
  // }

  render() {
    const { classes,filter_listener } = this.props;

 
    return (
      <React.Fragment>
        <Tooltip title={"Filter Table"}>
          <IconButton className={classes.iconButton} onClick={filter_listener}>
            <FilterListIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar_print);