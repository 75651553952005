import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Check from "@material-ui/icons/Check";
import CustomToolbar from "../../CustomToolbar";
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from "react-router-dom";


import IconButton from "@material-ui/core/IconButton";
import { Create, Delete, Add, CompareArrows } from "@material-ui/icons";

import LinearProgress from '@material-ui/core/LinearProgress';

import { deepOrange, deepPurple } from '@material-ui/core/colors';

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from '@date-io/date-fns';
import { useHistory } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import styles_theme from "assets/jss/material-dashboard-react/components/tasksStyle.js";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { number } from "prop-types";
import * as moment from 'moment'
import CustomToolbar_filter from "../../CustomToolbar_filter";

const useStyles_theme = makeStyles(styles_theme);

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "400",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles1 = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formControl: {
    marginTop: "26px",
    width: "100%",
  },
  root_avatar: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
}));

const useStyles = makeStyles(styles);

export default function Cards(props) {

  const history = useHistory();

  const roless = JSON.parse(localStorage.getItem('roles'));
  var curr_role = roless.filter((role) => role.formname == props.title);
  const user_id = JSON.parse(localStorage.getItem('user_id'));

  const classes = useStyles();
  const classes_theme = useStyles_theme(); //theme css
  const classes_form = useStyles1(); //theme css
  const [user, setUser] = useState([]);
  const [from_nb, setFromNb] = useState([]);
  const [to_nb, setToNb] = useState([]);

  let dat = new Date(Date.now());
  let datee = new Date(Date.now()); datee.setDate(datee.getDate() - 7);
  const [Submitform, setSubmitform] = useState({
    card_type_id: "",
    from_nbr: 0,
    to_nbr: 0,
    date: dat,
    expiry_date: null,
    user_id: user_id,


  }); //form submit
  const [SubmitTransfer, setSubmitTransfer] = useState({
    user_id: user_id,
    from_nbr: "",
    to_nbr: "",
    date: dat,
    branch_id: "",

  }); //form submit
  const [Submitfilter, setSubmitfilter] = useState({
    from_date: datee,
    to_date: new Date(Date.now()),
    card_number: "",
  });

  const [items, setItems] = useState([]); //table items
  const [itemsDetails, setItemDetails] = useState([]); //table items
  const [itemsTransfer, setItemTransfer] = useState([]); //table items
  const [RowID, setRowID] = useState(-1); //table items

  const [loading, setloading] = useState(true); //loader
  const [openTableDetails, setOpenTableDetails] = useState(false);
  const [openTabletransfer, setOpenTableTransfer] = useState(false);
  const [openAddTransfer, setOpenAddTransfer] = useState(false);


  const [modal_Title, setmodal_Title] = useState("Add"); //table items
  const [cardName, setCardName] = useState([]);
  const [branch, setBranch] = useState([]);

  const MySwal = withReactContent(Swal); //swal

  const classes1 = useStyles1();
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);


  const handleClickOpen = (rowID, modal_Title) => {
    setOpen(true);
    setRowID(rowID);
    setmodal_Title(modal_Title);
    /////////////////////////////////////////////
    fetch(`${process.env.REACT_APP_API_BASE_URL}` +
      "Cards/index.php?id=" + items[rowID].serial
    )
      .then((response) => response.json())
      .then((data) => {
        setSubmitform({
          card_type_id: data[0]["card_type_id"],
          from_nbr: data[0]["from_nbr"],
          to_nbr: data[0]["to_nbr"],
          user_id: data[0]["user_id"],
          expiry_date: data[0]["expiry_date"],
          date: data[0]["date"]

        });
      })
      .catch((error) => {
        alert("error");
        console.log("There was an error!", error);
      });
    ////////////////////////////////////////////////
  };

  const handlechange_date = (date) => {
    setSubmitform({
      ...Submitform,
      "expiry_date": new Date(date)
    });
  };
  const handleClose = () => {
    setOpen(false);
    setOpenTableDetails(false);
    setOpenTableTransfer(false);
    setOpenFilter(false);
  };
  const handleclose_add_transfer = () => {
    setOpenAddTransfer(false);

  }

  const handlesave = () => {

    if (Number(Submitform.from_nbr) <= Number(Submitform.to_nbr)) {

      if (RowID == -1) {
        const requestOptions = {
          method: "POST",
          body: JSON.stringify(Submitform),
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}` +
          "Cards/store.php?action=1",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data == "Some Number of range already used") {
              alert("Some Number of range already used");
            } else {
              var old_arr = items;

              old_arr.push(data[0]);

              setItems(old_arr);

              handleClose();
            }
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });
      } else {



        const requestOptions = {
          method: "POST",
          body: JSON.stringify({ ID: items[RowID].serial, ...Submitform }),
        };

        fetch(`${process.env.REACT_APP_API_BASE_URL}` +
          "Cards/store.php?action=3",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data == 0) {
              //error
            } else {

              let arr_items = items;
              arr_items[RowID] = data[0]
              setItems(arr_items);
              handleClose();
            }
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });

      }
    } else {
      alert("The order numbers is not correct ");
    }

  };
  const handlesave_filter = () => {

    getTableData(0);
    handleClose();
  };
  const handlechange_fromdat = (date) => {
    setSubmitfilter({
      ...Submitfilter,
      "from_date": new Date(date)
    });
  };

  const handlechange_todat = (date) => {
    setSubmitfilter({
      ...Submitfilter,
      "to_date": new Date(date)
    });
  };

  const handlesave_transfer = () => {
    if (Number(SubmitTransfer.from_nbr) <= Number(SubmitTransfer.to_nbr)) {
      if (Number(SubmitTransfer.from_nbr) >= from_nb && Number(SubmitTransfer.to_nbr) <= to_nb) {
        const requestOptions = {
          method: "POST",
          body: JSON.stringify(SubmitTransfer),
        };
        fetch(`${process.env.REACT_APP_API_BASE_URL}` +
          "transfer/store.php?action=1&initial_from=" + from_nb + "&initial_to=" + to_nb,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data == "Invalid numbers") {
              alert("Invalid numbers");
            } else {
              var old_arr = itemsTransfer;
              old_arr.push(data[0]);
              setItemTransfer(old_arr);
              handleclose_add_transfer();
            }
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });

      } else {
        alert("Out of range");
      }


    } else {
      alert("Wrong order of numbers");
    }
  }

  const add_click = () => {
    setOpen(true);
    setRowID(-1);
    setSubmitform({
      card_type_id: "",
      from_nbr: 0,
      to_nbr: 0,
      expiry_date: null,
      user_id: user_id,
      date: dat
    });
    setmodal_Title("Add");
  };

  const add_click_transfer = () => {
    getBranches();
    setOpenAddTransfer(true);
    setRowID(-1);
    setSubmitTransfer({
      user_id: user_id,
      from_nbr: 0,
      to_nbr: 0,
      date: dat,
      branch_id: "",
    });
    setmodal_Title("Add");
  };

  const handlechange_select = (event) => {
    const { name, value } = event.target;

    setSubmitform({
      ...Submitform,
      [name]: value,
    });
  };
  const handlechange_select_transfer = (event) => {
    const { name, value } = event.target;

    setSubmitTransfer({
      ...SubmitTransfer,
      [name]: value,
    });
  };

  const handlechangeinput = (event) => {
    const { id, value, checked, type } = event.target;
    if (type == "checkbox") {
      setSubmitform({
        ...Submitform,
        [id]: checked,
      });
    } else {
      setSubmitform({
        ...Submitform,
        [id]: value,
      });
    }
  };
  const handlechangeinput_filter = (event) => {
    const { id, value, checked, type } = event.target;
    if (type == "checkbox") {
      setSubmitfilter({
        ...Submitfilter,
        [id]: checked,
      });
    } else {
      setSubmitfilter({
        ...Submitfilter,
        [id]: value,
      });
    }
  };
  const handlechangeinput_transfer = (event) => {
    const { id, value, checked, type } = event.target;
    if (type == "checkbox") {
      setSubmitTransfer({
        ...SubmitTransfer,
        [id]: checked,
      });
    } else {
      setSubmitTransfer({
        ...SubmitTransfer,
        [id]: value,
      });
    }
  };




  function deleterows(row) {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(row),
    };

    MySwal.fire({
      title: "<span style='color:white'>Are you sure? You won't be able to revert this!</span>",
      icon: "warning",
      toast: true,
      position: "top-end",
      background: "#212529",
      showCancelButton: true,
      confirmButtonColor: "#04d401",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}` +
          "Cards/store.php?action=2",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data == "Can not be deleted") {
              alert("Your can not delete this card");
            } else {
              var data1 = items;

              row.map((row) => {
                data1 = data1.filter((user) => user.serial !== row.serial);
              });
              setItems(data1);
            }
          })
          .catch((error) => {
            alert("error");
            console.log("There was an error!", error);
          });

        Swal.fire({
          title: "<span style='color:white'>Deleted!</span>",
          icon: "success",
          toast: true,
          position: "top-end",
          background: "#212529",
          showCancelButton: false,
          timer: 3000,
          showConfirmButton: false
        })


      }
    });
  }
  const [page, setpage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const getTableData = (requiredPage) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Cards/index.php?page=" + requiredPage + "&fromdat=" + moment(Submitfilter.from_date).format("YYYY-MM-DD") + "&todat=" + moment(Submitfilter.to_date).format("YYYY-MM-DD") + "&card_number=" + Submitfilter.card_number)
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          setItems(data.data);
          setTotalItems(data.total_items);
          setloading(false)
        }
      })
      .catch((error) => {
        alert(error.toString());
      });
  }

  //get data for table
  useEffect(() => {
    setpage(0);
    getTableData(0);
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Card_type/index.php")
      .then((response) => response.json())
      .then((data) => {
        const ruleList = data.map((data) => (
          <MenuItem value={data.serial}>{data.card_name}</MenuItem>
        ));
        setCardName(ruleList);
      })
      .catch((error) => {
        alert(error.toString());
      });
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Users/index.php?id=" + user_id)
      .then((response) => response.json())
      .then((data) => {
        if (data != null) {
          setUser(data[0]["username"]);
          setloading(false)
        }
      })
      .catch((error) => {
        alert(error.toString());
      });
  }, []);

  const [pageDetails, setpageDetails] = useState(0);
  const [totalDetails, setTotalDetails] = useState(0);

  const getDetails = (requiredPage, rowID) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` +
      "Cards_detail/index.php?id=" + rowID + "&page=" + requiredPage
    )
      .then((response) => response.json())
      .then((data) => {
        setItemDetails(data.data);
        setTotalDetails(data.total_items);
      })
      .catch((error) => {
        alert("error");
        console.log("There was an error!", error);
      });
  }

  const handleClickOpenDetails = (rowID) => {
    setOpenTableDetails(true);
    setRowID(rowID);
    setpageDetails(0);
    getDetails(0, rowID);

  };


  const handleClickOpenTransfer = (from_nb, to_nb, modal_Title) => {
    setOpenTableTransfer(true);
    setFromNb(from_nb);
    setToNb(to_nb);
    /////////////////////////////////////////////
    fetch(`${process.env.REACT_APP_API_BASE_URL}` +
      "transfer/index.php?id=" + user_id + "&from_nbr=" + from_nb + "&to_nbr=" + to_nb
    )
      .then((response) => response.json())
      .then((data) => {
        setItemTransfer(data);
      })
      .catch((error) => {
        alert("error");
        console.log("There was an error!", error);
      });
    ////////////////////////////////////////////////

  };

  const getBranches = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}` + "Department/index.php?action=1")
      .then((response) => response.json())
      .then((data) => {
        const ruleList = data.data.map((data) => (
          <MenuItem value={data.serial}>{data.name}</MenuItem>
        ));
        setBranch(ruleList);
      })
      .catch((error) => {
        alert(error.toString());
      });
  }

  const filter_listener = () => {
    setOpenFilter(true);

  };
  const handleclear = () => {

    setSubmitfilter({
      from_date: datee,
      to_date: new Date(Date.now()),
      card_number: "",
    })
  }

  const columns = [

    {
      name: "card_name",
      label: "Card Name"
    },
    {
      name: "from_nbr",
      label: "From"
    },
    {
      name: "to_nbr",
      label: "To"
    },
    {
      name: "diff",
      label: "Total",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const serial = items[dataIndex].serial;
          console.log(serial);

          return (
            <>
              <span style={{ cursor: "pointer" }} onClick={() => {
                handleClickOpenDetails(serial);

              }}>{items[dataIndex].diff}</span>
            </>
          )
        }
      }
    },
    {
      name: "used",
      label: "Total Used"
    },
    {
      name: "remaining",
      label: "Total Remaining"
    },
    {
      name: "expiry_date",
      label: "Expiry Date"
    },
    {
      name: "username",
      label: "User"
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        display: curr_role[0]['canedit'] == "1" || curr_role[0]['candelete'] == "1" ? true : false,
        empty: true,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const from = items[dataIndex].from_nbr;
          const to = items[dataIndex].to_nbr;

          return (
            <div>
              {
                curr_role[0]['canedit'] == "1" ?
                  <Tooltip title={"Transfer"}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => handleClickOpenTransfer(from, to, "Edit")}
                    >
                      <CompareArrows></CompareArrows>
                    </IconButton>
                  </Tooltip>


                  : ""


              }

              {
                curr_role[0]['candelete'] == "1" ?
                  <Tooltip title={"Delete"}>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        let myItems = [];
                        myItems.push({ serial: items[dataIndex].serial });
                        deleterows(myItems);
                      }}
                    >
                      <Delete></Delete>
                    </IconButton>
                  </Tooltip>
                  : ""


              }

            </div>
          );
        },
      },
    },
  ];

  const detailsColumns = [

    {
      name: "number",
      label: "Number"
    },
    {
      name: "transaction_branch",
      label: "Transaction Branch"
    },
    {
      name: "card_name",
      label: "Card"
    },
    {
      name: "expiry_date",
      label: "Expiry Date"
    },
    {
      name: "username",
      label: "User"
    },
    {
      name: "branch_name",
      label: "Branch"
    },
  ];
  const transferColumns = [

    {
      name: "fullname",
      label: "Username"
    },
    {
      name: "date",
      label: "Date"
    },
    {
      name: "from_nbr",
      label: "From"
    },
    {
      name: "to_nbr",
      label: "To"
    },
    {
      name: "branch_name",
      label: "Branch Name"
    }
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: curr_role[0]['candelete'] == "1" ? true : false,

    onRowsDelete: (rowsDeleted) => {
      let myItems = [];

      rowsDeleted.data.map((anObjectMapped, index) => {
        myItems.push({ serial: items[anObjectMapped.dataIndex].serial });
      });
      deleterows(myItems);

      return false;
    },

    customToolbar: () => {

      return (<span>
        {
          curr_role[0]['canedit'] == "1" ?
            <CustomToolbar listener={add_click} />
            : ""
        }
        {
          <CustomToolbar_filter filter_listener={filter_listener} />
        }
      </span>);

    },
    filter: false,
    serverSide: true,
    //count, // Use total number of items
    count: totalItems, // Unknown number of items
    page,
    rowsPerPage: 50,
    rowsPerPageOptions: [50],
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        changePage(tableState.page);
      }
    }
  };

  const changePage = (page) => {
    setpage(page);
    getTableData(page)
  }

  const detailsOptions = {
    filterType: "dropdown",
    selectableRows: curr_role[0]['candelete'] == "1" ? false : false,

    onRowsDelete: (rowsDeleted) => {
      console.log("delete");
    },
    serverSide: true,
    //count, // Use total number of items
    count: totalDetails, // Unknown number of items
    pageDetails,
    rowsPerPage: 50,
    rowsPerPageOptions: [50],
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        changePageDetails(tableState.page);
      }
    }

  };
  const changePageDetails = (pageDetails) => {
    setpageDetails(pageDetails);
    getDetails(pageDetails, RowID);
  }

  const transferOptions = {
    filterType: "dropdown",
    selectableRows: curr_role[0]['candelete'] == "1" ? true : false,

    onRowsDelete: (rowsDeleted) => {
      let myItems = [];

      rowsDeleted.data.map((anObjectMapped, index) => {
        myItems.push({ serial: items[anObjectMapped.dataIndex].serial });
      });
      deleterows(myItems);
      return false;
    },
    customToolbar: () => {

      return (<span>
        {
          curr_role[0]['canedit'] == "1" ?
            <CustomToolbar listener={add_click_transfer} />
            : ""
        }
      </span>);

    },
  };



  return (
    <GridContainer>

      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>{props.title}</h4>
          </CardHeader>
          <CardBody>
            {loading ? <div style={{ textAlign: "center" }}><CircularProgress /></div> : <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title=""
                data={items}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
            }
          </CardBody>
        </Card>
      </GridItem>

      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                {modal_Title + " " + props.title}
              </Typography>
              <Button autoFocus color="inherit" onClick={handlesave}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <InputLabel id="ticket_status_filter-label">Card Type</InputLabel>
                  <Select
                    labelId="ticket_status_filter-label"
                    name="card_type_id"
                    value={Submitform.card_type_id}
                    onChange={handlechange_select}
                  >
                    {
                      cardName
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <CustomInput
                    labelText="User Name"
                    id="user_id"
                    inputProps={{
                      disabled: true,
                      onChange: handlechangeinput,
                      value: user,
                    }}
                    formControlProps={{
                      fullWidth: true,
                      style: { margin: "0px" }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="From"
                  id="from_nbr"
                  inputProps={{
                    onChange: handlechangeinput,
                    value: Submitform.from_nbr,
                    type: "number"
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="To"
                  id="to_nbr"
                  inputProps={{
                    onChange: handlechangeinput,
                    value: Submitform.to_nbr,
                    type: "number"
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="expiry_date"
                    label=" Expiry Date"
                    value={Submitform.expiry_date}
                    style={{ width: "100%" }}
                    onChange={handlechange_date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    disabled
                    variant="inline"
                    format="yyyy-MM-dd"
                    margin="normal"
                    id="date"
                    label="Date"
                    value={Submitform.date}
                    style={{ width: "100%" }}
                    onChange={handlechange_date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>


            </Grid>
          </div>


        </Dialog>
        <Dialog
          fullScreen
          open={openTableDetails}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                Details
              </Typography>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <MUIDataTable
              title=""
              data={itemsDetails}
              columns={detailsColumns}
              options={detailsOptions}
            />
          </div>


        </Dialog>


        <Dialog
          fullScreen
          open={openTabletransfer}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                Transfer History
              </Typography>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <MUIDataTable
              title=""
              data={itemsTransfer}
              columns={transferColumns}
              options={transferOptions}
            />
          </div>


        </Dialog>

        <Dialog
          fullScreen
          open={openAddTransfer}
          onClose={handleclose_add_transfer}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleclose_add_transfer}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                Make a transfer
              </Typography>
              <Button autoFocus color="inherit" onClick={handlesave_transfer}>
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <Grid container spacing={3}>


              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="From Number"
                  id="from_nbr"
                  inputProps={{
                    type: "number",
                    onChange: handlechangeinput_transfer,
                    value: SubmitTransfer.from_nbr,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  labelText="To Number"
                  id="to_nbr"
                  inputProps={{
                    type: "number",
                    onChange: handlechangeinput_transfer,
                    value: SubmitTransfer.to_nbr,
                  }}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes_form.formControl}>
                  <InputLabel id="ticket_status_filter-label">Branch</InputLabel>
                  <Select
                    labelId="ticket_status_filter-label"
                    name="branch_id"
                    value={SubmitTransfer.branch_id}
                    onChange={handlechange_select_transfer}
                  >
                    {
                      branch
                    }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>


        </Dialog>
        <Dialog
          fullScreen
          open={openFilter}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes1.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes1.title}>
                {"Filter " + props.title}
              </Typography>
              <Button autoFocus color="inherit"
                onClick={handleclear}
              >
                Clear filters
              </Button>

              <Button autoFocus color="inherit"
                onClick={handlesave_filter}
              >
                save
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ padding: "50px" }}>
            <Grid container spacing={3}>
              <FormControl className={classes_form.formControl}>
                <Grid item xs={12} sm={6}>
                  <CustomInput
                    labelText="Card Number"
                    id="card_number"
                    inputProps={{
                      onChange: handlechangeinput_filter,
                      value: Submitfilter.card_number,
                      type: "number"
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
              </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="from_date"
                    label="From Date"
                    value={Submitfilter.from_date}
                    style={{ width: "100%" }}
                    onChange={handlechange_fromdat}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="to_date"
                    label="To Date"
                    value={Submitfilter.to_date}
                    style={{ width: "100%" }}
                    onChange={handlechange_todat}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  /></Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </div>
        </Dialog>



      </div>
    </GridContainer>
  );

  function getMuiTheme() {
    return createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {},
          paper: {
            //  height: 'inherit',
            boxShadow: "none",
          },
          responsiveBase: {
            height: 'calc(100vh - 300px)!important'
          },
          responsiveScroll: {
            //  maxHeight: 'none',
            //  height: 'calc(100% - 128px)'
          },
        },
        MUIDataTableBodyRow: {
          root: {
            "&:nth-child(odd)": {
              backgroundColor: "#ebebeb",

            },
          },
        },
        MUIDataTableBodyCell: {
          root: {
            paddingTop: "0px",
            paddingBottom: "0px"
          },
        },
      },
    });
  }
}
