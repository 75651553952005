/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import HomeIcon from '@material-ui/icons/Home';
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
import Home from "views/home/home";
import Department from "views/Department/department";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';


// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";
import CarType from "views/Card_type/card_type";
import Driver from "views/Driver/driver";
import Vehicle from "views/Vehicle/vehicle";
import Cards from "views/Cards/cards";
import Station from "views/Station/station";
import CardsDetail from "views/cards_detail/cards_detail";
import Users from "views/Users/users";
import SummaryByBranch from "views/Summary/summaryByBranch";
import SummaryByVehicle from "views/Summary/summaryByVehicle";
const dashboardRoutes = [
  {
    path: "/Home",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: HomeIcon,
    component: Home,
    layout: "/admin",
  },
  {
    path: "/MasterData",
    name: "Master Data",
    rtlName: "قائمة الجدول",
    icon: Dashboard,
    component: CarType,
    layout: "/admin",
    parent:true,
    tabs:[
      {
        path: "/Users",
        name: "Users",
        rtlName: "قائمة الجدول",
        icon: Person,
        component: Users,
        layout: "/admin",
      },
      {
        path: "/Cards",
        name: "Cards",
        rtlName: "قائمة الجدول",
        icon: Person,
        component: Cards,
        layout: "/admin",
      },
      {
        path: "/CardType",
        name: "Card Type",
        rtlName: "قائمة الجدول",
        icon: Person,
        component: CarType,
        layout: "/admin",
      },
      // {
      //   path: "/CardsDetail",
      //   name: "Cards Detail",
      //   rtlName: "قائمة الجدول",
      //   icon: Person,
      //   component: CardsDetail,
      //   layout: "/admin",
      // },
      {
        path: "/Branch",
        name: "Branch",
        rtlName: "قائمة الجدول",
        icon: Person,
        component: Department,
        layout: "/admin",
      },
      // {
      //   path: "/Drivers",
      //   name: "Drivers",
      //   rtlName: "قائمة الجدول",
      //   icon: Person,
      //   component: Driver,
      //   layout: "/admin",
      // },
      {
        path: "/Vehicle",
        name: "Vehicle",
        rtlName: "قائمة الجدول",
        icon: Person,
        component: Vehicle,
        layout: "/admin",
      },
      {
        path: "/Station",
        name: "Station",
        rtlName: "قائمة الجدول",
        icon: Person,
        component: Station,
        layout: "/admin",
      },
      
    ]
  },

  {
    path: "/Summary",
    name: "Summary",
    rtlName: "قائمة الجدول",
    icon: BrandingWatermarkIcon,
    component: SummaryByBranch,
    layout: "/admin",
    parent:true,
    tabs:[

      {
        path: "/SummaryByBranch",
        name: "Summary by Branch",
        rtlName: "قائمة الجدول",
        icon: BrandingWatermarkIcon,
        component: SummaryByBranch,
        layout: "/admin",
      },
      {
        path: "/SummaryByVehicle",
        name: "Summary by Vehicle",
        rtlName: "قائمة الجدول",
        icon: BrandingWatermarkIcon,
        component: SummaryByVehicle,
        layout: "/admin",
      },
    ]
  },

  {
    path: "/Logout",
    name: "Logout",
    rtlName: "الرموز",
    icon: ExitToAppIcon,
    component: Vehicle,
    layout: "/admin",
    parent: false,
  },
];

export default dashboardRoutes;
